.spin-loading-hooks {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: auto;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.spin-loading-show {
  z-index: 1;
  background: rgba(255, 255, 255, 0.8);
}
